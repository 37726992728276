import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="dropdown"
export default class extends Controller {
  static targets = ["list", "listResponsive", "listHeader"];

  declare listTarget: HTMLElement;
  declare hasListTarget: boolean;
  declare listResponsiveTarget: HTMLElement;
  declare hasListResponsiveTarget: boolean;
  declare listHeaderTarget: HTMLElement;
  declare hasListHeaderTarget: boolean;

  connect() {
    const main = document.getElementById("main");
    if (main && this.hasListHeaderTarget) {
      main.addEventListener("click", () => {
        this.listHeaderTarget.classList.add("hidden");
      });
    }
  }

  toggle() {
    if (this.hasListTarget) {
      this.listTarget.classList.toggle("hidden");
    }
  }

  toggleResponsive() {
    if (this.hasListResponsiveTarget) {
      this.listResponsiveTarget.classList.toggle("hidden");
    }
  }

  toggleHeader() {
    if (this.hasListHeaderTarget) {
      this.listHeaderTarget.classList.toggle("hidden");
    }
  }
}
