import { Controller } from "@hotwired/stimulus";

type ScrollBehavior = "auto" | "instant" | "smooth";

// Connects to data-controller="scroll-to"
export default class extends Controller<HTMLElement> {
  static targets = ["element"];

  declare hasElementTarget: boolean;
  declare elementTarget: HTMLElement;

  static values = {
    offset: Number,
    behavior: String,
  };

  declare hasOffsetValue: boolean;
  declare offsetValue: number;
  declare hasBehaviorValue: boolean;
  declare behaviorValue: ScrollBehavior;

  connect() {
    this.target.addEventListener(this.eventType, this.performScroll);
  }

  performScroll = (e: Event) => {
    e.preventDefault();
    let id: string;

    switch (this.target.tagName) {
      case "SELECT":
        id = (this.target as HTMLSelectElement).value;
        break;
      default:
        id = (this.target as HTMLAnchorElement).hash;
    }

    const scrollTarget = document.querySelector(id);

    if (!scrollTarget) {
      console.warn(`[stimulus-scroll-to] Element with the id "${id}" could not be found`);
      return;
    }

    window.scrollTo({
      top: scrollTarget.getBoundingClientRect().top + window.scrollY - this.offset,
      behavior: this.behavior,
    });
  };

  disconnect() {
    this.target.removeEventListener(this.eventType, this.performScroll);
  }

  get target() {
    if (this.hasElementTarget) {
      return this.elementTarget;
    }

    return this.element;
  }

  get eventType() {
    switch (this.target.tagName) {
      case "SELECT":
        return "change";
      default:
        return "click";
    }
  }

  get offset() {
    if (this.hasOffsetValue) {
      return this.offsetValue;
    }

    return 0;
  }

  get behavior(): ScrollBehavior {
    if (this.hasBehaviorValue) {
      return this.behaviorValue;
    }

    return "smooth";
  }
}
