import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="disciplines-selector"
export default class extends Controller {
  static targets = ["optionsLi", "div", "checkedDisciplinesList", "checkbox"];

  declare optionsLiTargets: HTMLLIElement[];
  declare hasOptionsLiTarget: boolean;
  declare divTarget: HTMLDivElement;
  declare hasDivTarget: boolean;
  declare checkedDisciplinesListTarget: HTMLElement;
  declare hasCheckedDisciplinesListTarget: boolean;
  declare checkboxTargets: HTMLInputElement[];
  declare hasCheckboxTarget: boolean;


  connect() {
    for (let li of this.optionsLiTargets) {
      let div: Element = li.firstElementChild
      let legend: Element =  div.firstElementChild
      legend.classList.add('hover:cursor-pointer')

      legend.addEventListener("click", () => {
        for (let child of div.children) {
          if (child.firstElementChild != null && child.firstElementChild.getAttribute("type") == "checkbox"){
            child.firstElementChild.checked = !child.firstElementChild.checked;
          }
        }
      });
    }
  }
  display() {
    if (this.hasDivTarget) {
      if (this.divTarget.classList.contains("hidden")) {
        this.divTarget.classList.remove("hidden");
      } else {
        this.divTarget.classList.add("hidden");
      }
    }
  }

  displayDisciplinesNames(){
    let list = this.checkedDisciplinesListTarget.innerText.split(', ')
    for(let cb of this.checkboxTargets){
      let disciplineName = cb.labels[0].innerText
      if(cb.checked && !list.includes(disciplineName)) {
        list.push(disciplineName);
      }else if (!cb.checked && list.includes(disciplineName))
        list = list.filter(name =>{return name != disciplineName});
      }
      this.checkedDisciplinesListTarget.innerText = list.filter(name => {return name != ""}).join(', ')
  }
}
