/** @type {import("tailwindcss").Config} */

export default {
  theme: {
    fontFamily: {
      // sans: ["Raleway", "sans-serif"],
      sans: [
        "cairo",
        "ui-sans-serif",
        "system-ui",
        "-apple-system",
        "BlinkMacSystemFont",
        "Segoe UI",
        "Roboto",
        "Helvetica Neue",
        "Arial",
        "Noto Sans",
        "sans-serif",
        "Apple Color Emoji",
        "Segoe UI Emoji",
        "Segoe UI Symbol",
        "Noto Color Emoji",
      ],
    },
    extend: {
      backgroundImage: (theme) => ({
        bib: "url('dossard-ikinoa.png')",
      }),
      keyframes: {
        "slide-in-up": {
          from: {
            transform: "translate3d(0, 100%, 0)",
            visibility: "visible",
          },
          to: {
            transform: "translate3d(0, 0, 0)",
          },
        },
        "slide-out-down": {
          from: {
            transform: "translate3d(0, 0, 0)",
          },
          to: {
            visibility: "hidden",
            transform: "translate3d(0, 100%, 0)",
          },
        },
      },
      animation: {
        "slide-in-up": "slide-in-up 0.5s ease-in-out",
        "slide-out-down": "slide-out-down 0.5s ease-in-out",
      },
    },
  },
  content: [
    "./app/views/**/*.html.slim",
    "./app/helpers/**/*.rb",
    "./app/assets/stylesheets/**/*.css",
    "./app/javascript/**/*.{ts,js}",
    "./config/**/*.yml",
    "./config/**/*.rb",
  ],
  plugins: [import("@tailwindcss/forms")],
};
