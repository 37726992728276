import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="carpooling"
export default class extends Controller {
  static targets = [
    "positionInput",
    "directionInput",
    "startTitle",
    "arrivalTitle",
    "eventAddress",
    "inputStartDate",
    "divBackDate",
    "divAddressForm",
  ];

  declare positionInputTargets: HTMLInputElement[];
  declare hasPositionInputTarget: boolean;
  declare directionInputTarget: HTMLInputElement;
  declare hasDirectionInputTarget: boolean;
  declare startTitleTarget: HTMLElement;
  declare hasStartTitleTarget: boolean;
  declare arrivalTitleTarget: HTMLElement;
  declare hasArrivalTitleTarget: boolean;
  declare eventAddressTarget: HTMLElement;
  declare hasEventAddressTarget: boolean;
  declare inputStartDateTarget: HTMLInputElement;
  declare hasInputStartDateTarget: boolean;
  declare divBackDateTarget: HTMLElement;
  declare hasDivBackDateTarget: boolean;
  declare divAddressFormTarget: HTMLElement;
  declare hasDivAddressFormTarget: boolean;

  connect() {
    if (this.hasDivBackDateTarget) {
      this.toggleDirection();
    }
    this.toggleBackDate();
  }

  setPosition() {
    setTimeout(() => {
      if (this.hasPositionInputTarget) {
        for (let input of this.positionInputTargets) {
          let index = this.positionInputTargets.indexOf(input);
          input.value = index.toString();
          let div = input.parentElement?.parentElement;
          let previousElement = div?.getElementsByClassName("step-number")[0];
          if (previousElement?.classList.contains("step-number")) {
            previousElement.innerHTML = previousElement.innerHTML.split("°")[0] + "°" + index.toString();
          }
        }
      }
    }, 100);
  }

  selectEvent(event) {
    let eventAddress = event.target.selectedOptions[0].dataset.address;
    let eventDate = event.target.selectedOptions[0].dataset.date.split(" ");
    eventDate = eventDate[0] + "T" + "07:00";

    if (this.hasEventAddressTarget) {
      if (eventAddress == null) {
        this.eventAddressTarget.classList.add("invisible");
      } else {
        this.eventAddressTarget.innerHTML =
          this.eventAddressTarget.innerHTML.split("</span>")[0] + "</span>" + eventAddress;
        this.eventAddressTarget.classList.remove("invisible");
      }
    }
    if (this.hasInputStartDateTarget && eventDate) {
      this.inputStartDateTarget.value = eventDate;
    }
    if (this.hasDivBackDateTarget && eventDate) {
      let inputBackDate = this.divBackDateTarget.getElementsByTagName("input")[0];
      if (inputBackDate) {
        inputBackDate.value = eventDate.replace("07", "19");
      }
    }
  }

  toggleDirection() {
    if (this.hasDirectionInputTarget && this.hasStartTitleTarget && this.hasArrivalTitleTarget) {
      if (this.directionInputTarget.value == "back") {
        this.startTitleTarget.classList.add("hidden");
        this.arrivalTitleTarget.classList.remove("hidden");
      } else {
        this.startTitleTarget.classList.remove("hidden");
        this.arrivalTitleTarget.classList.add("hidden");
      }
    } //else if (this.hasDirectionInputTarget && this.hasDivAddressFormTarget && this.hasEventAddressTarget) {
    // const parent = this.divAddressFormTarget.parentNode;
    // const eventAddressNextSibling = this.eventAddressTarget.nextElementSibling;
    // const addressFormNextSibling = this.divAddressFormTarget.nextElementSibling;
    // const eventAddressHtml = this.eventAddressTarget.outerHTML;
    // const divAddressFormHtml = this.divAddressFormTarget.outerHTML;

    // this.eventAddressTarget.remove();
    // this.divAddressFormTarget.remove();

    // parent?.insertBefore(divAddressFormHtml, eventAddressNextSibling);

    // eventAddressParent?.replaceChild(this.divAddressFormTarget, this.eventAddressTarget);
    // addressFormParent?.replaceChild(eventAddressBlock, this.divAddressFormTarget);

    // console.log(this.divAddressFormTarget);
    // console.log("*************************************************");
    // console.log(this.eventAddressTarget);
    //}
  }

  toggleBackDate() {
    if (this.hasDirectionInputTarget && this.hasDivBackDateTarget) {
      if (this.directionInputTarget.value == "go_and_back") {
        this.divBackDateTarget.classList.remove("hidden");
      } else {
        this.divBackDateTarget.classList.add("hidden");
      }
    }
  }
}
