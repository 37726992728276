import { Controller } from "@hotwired/stimulus";
import type { ActiveSectionResponder } from "$types/interfaces";

// Connects to data-controller="active-section"
export default class extends Controller<HTMLElement> {
  declare sections?: HTMLElement[];
  declare observer?: IntersectionObserver;

  static targets = ["element", "nav"];

  declare hasElementTarget: boolean;
  declare elementTarget: HTMLElement;
  declare hasNavTarget: boolean;
  declare navTarget: HTMLElement;

  static values = {
    navController: String,
  };

  declare hasNavControllerValue: boolean;
  declare navControllerValue: string;

  connect() {
    this.sections = [...this.target.querySelectorAll(":scope > *[id]")] as HTMLElement[];
    this.observer = new IntersectionObserver(this.observerHandler, {
      rootMargin: "70px",
    });

    for (const section of this.sections) {
      this.observer.observe(section);
    }
  }

  observerHandler = (entries: IntersectionObserverEntry[]) => {
    console.log(
      entries.map(
        (entry) =>
          `id: ${entry.target.getAttribute("id")}, isIntersecting: ${entry.isIntersecting}, Ratio: ${
            entry.intersectionRatio
          }`
      )
    );

    let activeSectionId;

    const sectionEntries = entries.map(({ target, isIntersecting }) => ({
      id: target.getAttribute("id")!,
      isIntersecting,
    }));

    if (sectionEntries.every((se) => !se.isIntersecting)) {
      const entrySectionIndex = this.sections?.findIndex(
        (section) => section.getAttribute("id") === sectionEntries[0].id
      );

      if (entrySectionIndex && entrySectionIndex > 0) {
        activeSectionId = this.sections?.at(entrySectionIndex - 1)?.getAttribute("id");
      }
    } else {
      activeSectionId = sectionEntries.find((se) => se.isIntersecting)?.id;
    }

    if (activeSectionId) {
      this.dispatch("changed", {
        detail: {
          activeSectionId: activeSectionId,
        },
      });

      if (this.hasNavTarget && this.hasNavControllerValue) {
        const navController = this.application.getControllerForElementAndIdentifier(
          this.navTarget,
          this.navControllerValue
        );

        if (navController) {
          ((<unknown>navController) as ActiveSectionResponder).activeSectionChanged(activeSectionId);
        }
      }
    }
  };

  disconnect() {
    this.observer?.disconnect();
    this.observer = undefined;
    this.sections = undefined;
  }

  get target() {
    if (this.hasElementTarget) {
      return this.elementTarget;
    }

    return this.element;
  }
}
