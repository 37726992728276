import { Controller } from "@hotwired/stimulus";
import type { ActiveSectionResponder } from "$types/interfaces";

// Connects to data-controller="tabs"
export default class extends Controller<HTMLElement> implements ActiveSectionResponder {
  static targets = ["list"];

  declare listTarget: HTMLElement;

  static classes = ["inactiveTab", "activeTab"];

  declare inactiveTabClasses: string[];
  declare activeTabClasses: string[];

  activeSectionChanged = (activeSectionId: string) => {
    for (const tab of [...this.listTarget.querySelectorAll(":scope > *")]) {
      const anchor = tab.querySelector("a");

      if (anchor) {
        if (anchor.hash.replace(/^#/, "") === activeSectionId) {
          tab.classList.remove(...this.inactiveTabClasses);
          tab.classList.add(...this.activeTabClasses);
        } else {
          tab.classList.remove(...this.activeTabClasses);
          tab.classList.add(...this.inactiveTabClasses);
        }
      }
    }
  };
}
