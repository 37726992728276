import { Controller } from "@hotwired/stimulus";
import type { LightGallerySettings } from "lightgallery/lg-settings";
import type { LightGallery } from "lightgallery/lightgallery";
import lightGallery from "lightgallery";
import "lightgallery/css/lightgallery.css";

// Connects to data-controller="lightbox"
export default class extends Controller<HTMLElement> {
  declare lightGallery?: LightGallery;

  static values = {
    options: Object,
  };

  declare optionsValue: LightGallerySettings;

  connect() {
    this.lightGallery = lightGallery(this.element, {
      ...this.defaultOptions,
      ...this.optionsValue,
    });
  }

  disconnect() {
    this.lightGallery?.destroy();
    this.lightGallery = undefined;
  }

  get defaultOptions(): LightGallerySettings {
    return {};
  }
}
