import { Application } from "@hotwired/stimulus";
import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "$railsRoot/tailwind.config";
import Croppr from "croppr";
import "vanilla-nested";
import "trix";

const application = Application.start();

// Configure Stimulus development experience
application.debug = false;
window.Stimulus = application;

// Extract breakpoints from the Tailwind configuration so we can use them inside Stimulus controllers
const { theme } = resolveConfig(tailwindConfig);
const breakpoints = Object.fromEntries(
  Object.entries(theme!.screens!).map(([k, v]) => [k, parseInt((v as string).replace("px", ""))]),
);

export { application, breakpoints };

document.addEventListener("turbo:before-fetch-request", () => {
  if (document.getElementById("spinner")) {
    document.getElementById("spinner").classList.remove("hidden");
  }
});
document.addEventListener("turbo:frame-render", () => {
  if (document.getElementById("spinner")) {
    document.getElementById("spinner").classList.add("hidden");
  }
  hideFlash();
});
document.addEventListener("turbo:load", () => {
  if (document.getElementById("spinner")) {
    document.getElementById("spinner").classList.add("hidden");
  }
  hideFlash();
});
document.addEventListener("turbo:before-fetch-response", () => {
  if (document.getElementById("spinner")) {
    document.getElementById("spinner").classList.add("hidden");
  }
  hideFlash();
});
document.addEventListener("turbo:render", () => {
  if (document.getElementById("spinner")) {
    document.getElementById("spinner").classList.add("hidden");
  }
  hideFlash();
});

function hideFlash() {
  const notice = document.getElementById("notice");
  if (notice) {
    setInterval(function () {
      notice.classList.add("hidden");
    }, 2500);
  }
  const alert = document.getElementById("alert");
  if (alert) {
    setInterval(function () {
      alert.classList.add("hidden");
    }, 2500);
  }
}
