import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="cookies-banner"
export default class extends Controller<HTMLElement> {
  connect() {
    this.element.addEventListener("animationend", this.handleSlideOutEnd);

    if (localStorage.getItem("cookies-banner.dismissed") !== "1") {
      this.element.classList.remove("hidden");
      this.element.classList.add("animate-slide-in-up");
    }
  }

  disconnect() {
    this.element.removeEventListener("animationend", this.handleSlideOutEnd);
  }

  close() {
    localStorage.setItem("cookies-banner.dismissed", "1");
    this.element.classList.remove("animate-slide-in-up");
    this.element.classList.add("animate-slide-out-down");
  }

  handleSlideOutEnd = (e: AnimationEvent) => {
    if (e.animationName === "slide-out-down") {
      this.element.classList.remove("animate-slide-out-down");
      this.element.classList.add("hidden");
    }
  };
}
