import { Controller } from "@hotwired/stimulus";
import type { SwiperOptions } from "swiper/types";
import Swiper from "swiper";
import { Autoplay, Navigation } from "swiper/modules";
import { breakpoints } from "./application";
import "swiper/css/bundle";

// Connects to data-controller="carousel"
export default class extends Controller<HTMLElement> {
  declare swiper?: Swiper;

  static values = {
    options: Object,
  };

  declare optionsValue: SwiperOptions;

  connect() {
    const wrapperElement = this.element.children[0];
    const slideElements = wrapperElement.children;

    this.element.classList.add("swiper");
    wrapperElement.classList.add("swiper-wrapper");

    for (const slideElement of slideElements) {
      slideElement.classList.add("swiper-slide");
    }

    this.swiper = new Swiper(this.element, {
      ...this.defaultOptions,
      ...this.optionsValue,
    });
  }

  disconnect() {
    this.swiper?.destroy();
    this.swiper = undefined;
  }

  get defaultOptions(): SwiperOptions {
    const { sm, md, lg } = breakpoints;

    return {
      modules: [Autoplay, Navigation],
      slidesPerView: 1,
      spaceBetween: 10,
      centerInsufficientSlides: true,
      breakpoints: {
        [sm]: {
          slidesPerView: 1,
        },
        [md]: {
          slidesPerView: 3,
        },
        [lg]: {
          slidesPerView: 5,
        },
      },
    };
  }
}
