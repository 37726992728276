import { Controller } from "@hotwired/stimulus";
const availableCharClass =
  "text-sm font-medium float-left char w-7 h-7 pt-1 mb-2 mr-2 text-center shadow border-slate-400 bg-white";
const extendedCharClass =
  "text-sm font-medium float-left char w-7 h-7 pt-1 mb-2 mr-2 text-center shadow border-slate-400 bg-red-200";

// Connects to data-controller="campaigns"
export default class extends Controller {
  static targets = [
    "messageInput",
    "messageDisplayDiv",
    "usedCharsNb",
    "remainingCharsNb",
    "messagesNb",
    "extendedCharsDiv",
    "manyMessagesDiv",
    "itemsCb",
    "statusCb",
  ];

  declare messageInputTarget: HTMLTextAreaElement;
  declare hasMessageInputTarget: boolean;
  declare messageDisplayDivTarget: HTMLElement;
  declare hasMessageDisplayDivTarget: boolean;
  declare usedCharsNbTarget: HTMLElement;
  declare hasUsedCharsNbTarget: boolean;
  declare remainingCharsNbTarget: HTMLElement;
  declare hasRemainingCharsNbTarget: boolean;
  declare messagesNbTarget: HTMLElement;
  declare hasMessagesNbTarget: boolean;
  declare extendedCharsDivTarget: HTMLElement;
  declare hasExtendedCharsDivTarget: boolean;
  declare manyMessagesDivTarget: HTMLElement;
  declare hasManyMessagesDivTarget: boolean;
  declare itemsCbTargets: HTMLInputElement[];
  declare hasItemsCbTarget: boolean;
  declare statusCbTargets: HTMLInputElement[];
  declare hasStatusCbTarget: boolean;
  declare timeout: number | undefined;

  connect() {
    this.analyseSms();
  }

  analyseSms() {
    if (this.timeout != undefined) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(() => {
      this.callAnalyseSms();
    }, 600);
  }

  callAnalyseSms() {
    if (this.hasMessageInputTarget) {
      const params = {
        content: this.messageInputTarget.value,
      };
      const spinner = document.getElementById("spinner");
      if (spinner) {
        spinner.classList.remove("hidden");
      }
      this.usedCharsNbTarget.classList.add("hidden");
      this.remainingCharsNbTarget.classList.add("hidden");
      this.messagesNbTarget.classList.add("hidden");

      fetch(`${window.location.href.split("/campaigns")[0]}/campaigns/analyse_sms_content`, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
        },
        body: JSON.stringify(params),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((json) => {
          this.displayUsedCharsNb(json.size);
          this.displayRemainingCharsNb(json.remaining_chars);
          this.displayMessagesNb(json.nb_messages);
          this.displayExtendedCharsDiv(json.extended_chars);
          this.displayAllChars(json.details);
          if (spinner) {
            spinner.classList.add("hidden");
          }
        });
    }
  }

  displayUsedCharsNb(nb) {
    if (this.hasUsedCharsNbTarget) {
      this.usedCharsNbTarget.innerText = nb;
      this.usedCharsNbTarget.classList.remove("hidden");
    }
  }

  displayRemainingCharsNb(nb) {
    if (this.hasRemainingCharsNbTarget) {
      this.remainingCharsNbTarget.innerText = nb;
      this.remainingCharsNbTarget.classList.remove("hidden");
    }
  }

  displayMessagesNb(nb) {
    this.messagesNbTarget.innerText = nb;
    this.messagesNbTarget.classList.remove("hidden");
    if (this.hasManyMessagesDivTarget) {
      if (nb > 1) {
        this.manyMessagesDivTarget.classList.remove("hidden");
      } else {
        this.manyMessagesDivTarget.classList.add("hidden");
      }
    }
  }

  displayExtendedCharsDiv(extendedChars) {
    if (this.hasExtendedCharsDivTarget) {
      if (extendedChars) {
        this.extendedCharsDivTarget.classList.remove("hidden");
      } else {
        this.extendedCharsDivTarget.classList.add("hidden");
      }
    }
  }

  displayAllChars(chars) {
    if (this.hasMessageDisplayDivTarget) {
      this.messageDisplayDivTarget.innerHTML = "";
      chars.forEach((char, index) => {
        let div = document.createElement("div");
        div.className = char.gsm ? availableCharClass : extendedCharClass;
        div.innerText = char.char;
        this.messageDisplayDivTarget.append(div);
        if (index > 0 && char.message_number != chars[index - 1].message_number) {
          let messagesSeparator = document.createElement("div");
          messagesSeparator.className = "overflow-hidden clear-both mt-5 mb-5";
          this.messageDisplayDivTarget.append(messagesSeparator);
        }
      });
    }
  }

  selectAllItems() {
    if (this.hasItemsCbTarget) {
      this.itemsCbTargets.forEach((cb) => {
        cb.checked = true;
      });
    }
  }

  selectNoItems() {
    if (this.hasItemsCbTarget) {
      this.itemsCbTargets.forEach((cb) => {
        cb.checked = false;
      });
    }
  }

  selectAllStatus() {
    if (this.hasStatusCbTarget) {
      this.statusCbTargets.forEach((cb) => {
        cb.checked = true;
      });
    }
  }

  selectNoStatus() {
    if (this.hasStatusCbTarget) {
      this.statusCbTargets.forEach((cb) => {
        cb.checked = false;
      });
    }
  }
}
