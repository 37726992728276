import { Controller } from "@hotwired/stimulus";
import L from "leaflet";
import "leaflet-routing-machine";

let map: any;
let icon: any;

// Connects to data-controller="leaflet"
export default class extends Controller {
  static values = {
    event: Array,
    steps: Array,
  };

  declare stepsValue: any[];
  declare hasStepsValue: boolean;
  declare eventValue: any[];
  declare hasEventValue: boolean;

  connect() {
    map = new L.Map("map", {
      center: new L.LatLng(48.085329, -1.696846),
      zoom: 8,
      zoomControl: true,
      scrollWheelZoom: false,
      attributionControl: false,
    });

    icon = L.icon({
      iconUrl: "/marker-icon.png",
      iconSize: [25, 41],
      iconAnchor: [12.5, 41],
    });

    L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
      maxZoom: 19,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      subdomains: ["a", "b", "c"],
    }).addTo(map);

    if (this.hasEventValue) {
      this.addMarker(this.eventValue[0], this.eventValue[1]);
    }

    if (this.hasStepsValue) {
      this.addRoute(this.stepsValue);
    }
  }

  addMarker(lat, lgt) {
    L.marker([lat, lgt], {
      icon: icon,
      riseOnHover: true,
      zIndexOffset: 2000,
      riseOffset: 5000,
    }).addTo(map);
  }

  addRoute(steps) {
    console.log(steps);
    let waypoints: any[] = [];
    steps.forEach((element) => {
      waypoints.push(L.latLng(element[0], element[1]));
    });
    console.log(waypoints);

    L.Routing.control({
      // waypoints: [L.latLng(44.8333, -0.5667), L.latLng(48.085329, -1.696846)],
      waypoints: waypoints,
      routeWhileDragging: true,
      // showAlternatives: true,
      formatter: new L.Routing.Formatter({ language: "fr" }),
      // containerClassName: 'hidden',
      createMarker: function (i, wp, nWps) {
        return L.marker(wp.latLng, {
          icon: icon,
        });
      },
    }).addTo(map);
  }
}
