import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="expand"
export default class extends Controller<HTMLElement> {
  declare originalHeight: number;
  declare containerHeight: number;

  static targets = ["element", "container", "button", "expandedContent", "condensedContent"];

  declare hasElementTarget: boolean;
  declare elementTarget: HTMLElement;
  declare containerTarget: HTMLElement;
  declare buttonTarget: HTMLElement;
  declare expandedContentTarget: HTMLElement;
  declare condensedContentTarget: HTMLElement;

  static values = {
    limit: Number,
    expanded: {
      type: Boolean,
      default: false,
    },
  };

  declare hasLimitValue: boolean;
  declare limitValue: number;
  declare expandedValue: boolean;

  static classes = ["button", "condensed"];

  declare buttonClasses: string[];
  declare condensedClasses: string[];

  connect() {
    this.originalHeight = this.target.getBoundingClientRect().height;

    if (this.originalHeight > this.limitValue) {
      this.target.style.overflow = "hidden";
      this.target.style.transition = "height ease-in-out .3s";
      this.containerTarget.classList.remove("hidden");
      this.containerHeight = this.containerTarget.getBoundingClientRect().height;
      this.buttonTarget.classList.add(...this.buttonClasses);
      this.updateHeight();
    }
  }

  toggle = () => {
    this.expandedValue = !this.expandedValue;
    this.updateHeight();
  };

  updateHeight = () => {
    if (this.expandedValue) {
      this.target.style.height = `${this.originalHeight + this.containerHeight + 24}px`;
      this.containerTarget.classList.remove(...this.condensedClasses);
      this.expandedContentTarget.classList.remove("hidden");
      this.condensedContentTarget.classList.add("hidden");
    } else {
      this.target.style.height = `${this.limitValue}px`;
      this.containerTarget.classList.add(...this.condensedClasses);
      this.condensedContentTarget.classList.remove("hidden");
      this.expandedContentTarget.classList.add("hidden");
    }
  };

  get target() {
    if (this.hasElementTarget) {
      return this.elementTarget;
    }

    return this.element;
  }

  get limit() {
    if (this.hasLimitValue) {
      return this.limitValue;
    }

    return 500;
  }
}
