import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="anchors"
export default class extends Controller {

  static targets = ["element"]
  static values = { use: Boolean}

  declare elementTarget: HTMLElement;
  declare hasElementTarget: boolean;
  declare useValue: boolean;
  declare hasUseValue: boolean;
  connect() {
    document.addEventListener('turbo:load', () => {
      if(this.hasUseValue && this.useValue && this.hasElementTarget){
        this.elementTarget.scrollIntoView({behavior: "smooth"})
      }
    })
  }
}
