import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="on-sticky"
export default class extends Controller<HTMLElement> {
  declare sentinal?: HTMLElement;
  declare observer?: IntersectionObserver;

  static targets = ["element"];

  declare hasElementTarget: boolean;
  declare elementTarget: HTMLElement;

  connect() {
    this.sentinal = document.createElement("div");
    this.sentinal.style.top = "-1px";
    this.sentinal.style.height = "1px";
    this.target.before(this.sentinal);

    this.observer = new IntersectionObserver(this.observerHandler);
    this.observer.observe(this.sentinal);
  }

  observerHandler = ([entry]: IntersectionObserverEntry[]) => {
    this.dispatch(entry.isIntersecting ? "unpinned" : "pinned", {
      detail: {
        element: this.target,
      },
    });
  };

  disconnect() {
    this.observer?.disconnect();
    this.observer = undefined;

    this.sentinal?.remove();
    this.sentinal = undefined;
  }

  get target() {
    if (this.hasElementTarget) {
      return this.elementTarget;
    }

    return this.element;
  }
}
