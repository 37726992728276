import { Controller } from "@hotwired/stimulus";
import SlimSelect from "slim-select";

// Connects to data-controller="slim-select"
export default class extends Controller {
  static targets = ["select"];

  static values = { itemId: String, mode: String };

  declare selectTarget: HTMLSelectElement;
  declare hasSelectTarget: boolean;
  declare itemIdValue: string;
  declare hasItemIdValue: boolean;
  declare modeValue: string;
  declare hasModeValue: boolean;

  connect() {
    if (this.hasSelectTarget) {
      let select = new SlimSelect({
        select: this.selectTarget,
        settings: {
          searchPlaceholder: "Rechercher",
          searchText: "Aucun résultat",
        },
        events: {
          afterChange: () => {
            if (this.hasItemIdValue) {
              const urlWithoutParams = window.location.toString().split("?")[0];
              if (this.hasModeValue && this.modeValue == "team") {
                document.location.href = `${urlWithoutParams}?item_id=${this.itemIdValue}&team_id=${
                  select.getSelected()[0]
                }`;
              } else {
                document.location.href = `${urlWithoutParams}?item_id=${this.itemIdValue}&type=individual&user_id=${
                  select.getSelected()[0]
                }`;
              }
            }
          },
        },
      });
    }
  }
}
