import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="popup"
export default class extends Controller {
  static targets = ["popupDoc", "popup"];

  declare popupDocTarget: HTMLElement;
  declare hasPopupDocTarget: boolean;
  declare popupTarget: HTMLElement;
  declare hasPopupTarget: boolean;
  connect() {}

  togglePopupPicture(event) {
    const docId = event.target.dataset.docId;
    const filename = event.target.dataset.filename;
    const eventId = event.target.dataset.eventId;

    if (this.hasPopupDocTarget) {
      if (docId == undefined) {
        this.popupDocTarget.getElementsByTagName("img")[0].src = "";
      } else {
        this.popupDocTarget.getElementsByTagName("img")[0].src =
          eventId == "" ? `/private_data/${docId}/${filename}` : `/events/${eventId}/private_data/${docId}/${filename}`;
      }
    }
    this.popupDocTarget.classList.toggle("hidden");
  }

  togglePopup() {
    if (this.hasPopupTarget) {
      this.popupTarget.classList.toggle("hidden");
    }
  }
}
