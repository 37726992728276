import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="toggle-display-element"
export default class extends Controller {

  static targets = ["element"]

  declare elementTarget: HTMLElement;
  declare hasElementTarget: boolean;
  connect() {  }

  toggle(){
    if (this.hasElementTarget ) {
      this.elementTarget.classList.toggle("hidden");
    }
  }
}
