import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="sticky-tabs"
export default class extends Controller<HTMLElement> {
  static targets = ["container", "nav", "list"];

  declare containerTarget: HTMLElement;
  declare navTarget: HTMLElement;
  declare listTarget: HTMLElement;

  static values = {
    sections: Array,
    pinned: { type: Boolean, default: false },
  };

  declare sectionsValue: string[];
  declare pinnedValue: boolean;

  static classes = ["unpinnedContainer", "pinnedContainer", "unpinnedNav", "pinnedNav", "unpinnedList", "pinnedList"];

  declare unpinnedContainerClasses: string[];
  declare pinnedContainerClasses: string[];
  declare unpinnedNavClasses: string[];
  declare pinnedNavClasses: string[];
  declare unpinnedListClasses: string[];
  declare pinnedListClasses: string[];

  unpinnedStyles = () => {
    this.pinnedValue = false;
  };

  pinnedStyles = () => {
    this.pinnedValue = true;
  };

  togglePinnedStyles = () => {
    this.pinnedValue = !this.pinnedValue;
  };

  pinnedValueChanged() {
    if (this.pinnedValue) {
      this.containerTarget.classList.remove(...this.unpinnedContainerClasses);
      this.navTarget.classList.remove(...this.unpinnedNavClasses);
      this.listTarget.classList.remove(...this.unpinnedListClasses);

      this.containerTarget.classList.add(...this.pinnedContainerClasses);
      this.navTarget.classList.add(...this.pinnedNavClasses);
      this.listTarget.classList.add(...this.pinnedListClasses);
    } else {
      this.containerTarget.classList.remove(...this.pinnedContainerClasses);
      this.navTarget.classList.remove(...this.pinnedNavClasses);
      this.listTarget.classList.remove(...this.pinnedListClasses);

      this.containerTarget.classList.add(...this.unpinnedContainerClasses);
      this.navTarget.classList.add(...this.unpinnedNavClasses);
      this.listTarget.classList.add(...this.unpinnedListClasses);
    }
  }
}
