import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";

let sortable;
// Connects to data-controller="sortable"
export default class extends Controller {
  static targets = ["list"];

  declare listTarget: HTMLUListElement;
  declare hasListTarget: boolean;

  connect() {
    sortable = Sortable.create(this.element, {
      // onEnd: this.onEnd.bind(this),
      onChange: this.onChange.bind(this),
    });
    this.onChange();
  }

  onEnd(event) {
    const { newIndex, item } = event;
  }

  onChange() {
    let lis = this.listTarget.getElementsByTagName("li");
    const spinner = document.getElementById("spinner");
    if (spinner) {
      spinner.classList.remove("hidden");
    }

    for (let i = 0; i < lis.length; i++) {
      const li = lis[i];
      let url = li.dataset.url;
      let params = {};
      if (url?.includes("price")) {
        params = { item_price: { position: i + 1 } };
      } else {
        params = { item: { position: i + 1 } };
      }

      fetch(url, {
        method: "PATCH",
        headers: {
          "Content-type": "application/json",
          "X-CSRF-Token": document?.querySelector('meta[name="csrf-token"]')?.getAttribute("content"),
        },
        body: JSON.stringify(params),
      }).then((response) => {
        if (spinner) {
          spinner.classList.add("hidden");
        }
      });
    }
  }
}
